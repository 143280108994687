<!--
### Meta tags helper
 -->
<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";
  import { serializeJsonLD } from "./lib/seralizeJsonLD";

  type MetaProps = {
    /** Meta title */
    title?: string;
    /** Meta description */
    description?: string;
    /** Socials image */
    image?: string;
    /** JSON LD data */
    jsonLD?: Record<string, string>;
  } & HTMLAttributes<HTMLDivElement>;

  const { title, description, image, jsonLD }: MetaProps = $props();
</script>

<svelte:head>
  {#if title}
    <title>{title}</title>
    <meta property="og:title" content={title} />
  {/if}

  {#if description}
    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
  {/if}

  {#if image}
    <meta property="og:image" content={image} />
  {/if}

  {#if jsonLD}
    {@html serializeJsonLD(jsonLD)}
  {/if}
</svelte:head>
