<script lang="ts">
  import type { SanityImageAsset } from "$generated/sanity";
  import { src } from "$src/lib/sanity";
  import BaseMeta from "@hyperfocal/design/components/Meta/Meta.svelte";

  type MetaProps = {
    title?: string;
    description?: string;
    image?: SanityImageAsset;
  };
  const { title, description, image }: MetaProps = $props();
</script>

<style></style>

<BaseMeta
  title={title ? `Hyperfocal | ${title}` : undefined}
  image={image ? src(image.url) : undefined}
  {description}
/>
